.search-form{
    width: 100%;
    max-width: 680px;
}
.search-form-elem{
    padding: 1.4rem 2.8rem;
    border-radius: 3.8rem;
}
.search-form-elem .form-control{
    font-size: 2rem;
    padding: 0.6rem;
}
.search-form-elem .form-control::placeholder{
    opacity: 0.9;
}
label {
    font-size: small;
}
.form-control {
    width: 100%;
}