.admin-upload {
  max-width: 800px;
  margin: 4rem auto;
  padding: 1rem;
}

.upload-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.upload-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  font-size: medium;
}

.paper-form-control {
  font-size: medium;
  width: 400px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f9f9f9;
  padding: 5px 10px;
}

.error {
  color: red;
  font-size: 0.9rem;
}

.submit-btn {
  margin: 20px 0;
  padding: 0.7rem;
  font-size: small;
  font-weight: 400;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.documents-table {
  width: 100%;
  margin-top: 2rem;
  border-collapse: collapse;
}

.documents-table th, .documents-table td {
  border: 1px solid #ccc;
  padding: 0.5rem;
  text-align: left;
}

.documents-table th {
  background-color: #007bff;
  color: #fff;
}

.documents-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
