.section {
  padding: 60px 20px;
}

.bg-white {
  background-color: #ffffff;
}

.bg-gray-light {
  background-color: #f9f9f9;
}

.bg-dark {
  background-color: #333;
  color: #fff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.flex {
  display: flex;
  gap: 20px;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.mb-20 {
  margin-bottom: 20px;
}

.rounded {
  border-radius: 10px;
}

.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text-large {
  font-size: 2.25rem;
  font-weight: 500;
}

.text-medium {
  font-size: 1.75rem;
}

.text-gray {
  color: #666;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #aaa;
}

p {
  padding: 10px 0;
}