.header-content{
    min-height: 90vh;
    background: linear-gradient(rgba(141, 39, 174, 0.3), rgba(141, 39, 174, 0.5)), url("../../images/image-1.jpg    ") center/cover no-repeat;
    flex-direction: column;
}
.main-title {
    font-size: 4.2rem;
}
.header-title{
    font-size: 2.72rem;
}
.header-text{
    margin-top: -1.8rem;
    margin-bottom: 2.8rem;
    font-weight: 500;
    max-width: 770px;
}